<template>
    <div class="err">
        <img
            src="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/static/expire.png"
            @click="$router.replace({ path: '/login' })"
        />
    </div>
</template>

<script>
export default {
    mounted() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('authToken');
    },
};
</script>

<style lang="scss" scoped>
.err {
    width: 100vw;
    height: 100vh;
    background: #fff;
    text-align: center;
}
</style>
